// /* image hover effect */
:root {
  --switch-color-1: rgb(240, 253, 253);
  --switch-color-2: #000000;
  --switch-color-3: #28afe2;
}
.theme-switcher {
  position: fixed;
  right: $px16;
  bottom: $px16;
  z-index: 1031;
  input {
    width: 0px;
    height: 0px;
    visibility: hidden;
  }

  label {
    position: relative;
    display: block;
    text-indent: -9999px;
    height: $px24;
    width: $px40;
    background-color: var(--switch-color-1);
    border-radius: 50px;
    cursor: pointer;
    transition: 0.5s ease background-color;
    border: 2px solid var(--switch-color-1);
    &::after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background-color: var(--switch-color-2);
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: 0.5s ease;
    }
  }

  input:checked + label {
    background-color: var(--switch-color-2);
    border: 2px solid var(--switch-color-3);
  }

  input:checked + label::after {
    background-color: var(--switch-color-1);
    left: calc(100% - 20px);
  }
}
