@import "./common/mixins";
@import "./common/variables";
@import "./common/color";
@import "./common/pixel";
@import "./common/utility";
@import "./common/aspect-ratio";
:root {
  --yellow: #ffad00;
  --yellow: #fba504;
  --green: #4caf50;
  --dark: #6c757d;
  --white: #ffff;
  --black: #000000;
  --light-grey: #eee;
  --bg-grey: #f7f7f7;
  --rfs-title: 2.3rem;
  --rfs-sub-title: calc(0.5rem + 0.5vw);
}
.admin-root {
  .sidebar {
    position: fixed;
    top: $px50;
    bottom: $px48;
    left: 0;
    z-index: 100;
    padding: $px16 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(var(--bg-color-rgb-invert), 1);
    color: var(--text-color);

    .list-group {
      .list-group-item {
        background-color: rgba(var(--bg-color-rgb-invert), 1);
        border-color: rgba(var(--bg-color-rgb), 0.25);
        padding-top: $px10;
        padding-bottom: $px10;
      }
    }
  }

  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }

  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .sidebar .nav-link {
    margin-right: 4px;
    color: var(--text-color-invert);
  }

  .sidebar .nav-link.active {
    color: #333;
  }

  .sidebar .nav-link:hover,
  .sidebar .nav-link.active {
    color: rgba(var(--main-color-rgb), 0.75);
  }

  .sidebar-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .navbar .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
  }

  .navbar .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
  }

  .display-between {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  .admin-block {
    padding-top: $px50;
    height: 100%;
  }
  .admin-header {
    background-color: rgba(var(--dark-rgb-invert), 0.65);
    .navbar-brand {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 1rem;
      background-color: rgba(var(--bg-color-rgb), 0.25);
      // box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
    }
  }
  .form-control-dark {
    color: var(--text-color);
    background-color: rgba(var(--dark-rgb-invert), 0.75);
    border-color: var(--bg-color-invert);
    &::placeholder {
      color: var(--text-color);
    }
    &:focus {
      background-color: rgba(var(--bg-color-rgb-invert), 0.15);
      border-color: transparent;
      box-shadow: 0 0 0 1px rgba(var(--main-color-rgb), 0.15);
    }
  }
  .admin-footer {
    display: flex;
    align-items: center;
    height: $px48;
    padding: 4px $px16;
    background-color: var(--bg-color);
    color: var(--text-color);
  }
}
