@import "../assets/scss/common/color";

.contact {
  min-height: calc(100vh - 36px);
  display: flex;
  align-items: center;

  .form {
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.1);
      padding: 10px;
      background: rgba(10, 10, 10, 0.4);
      color: #fff;
      margin-bottom: 15px;
    }

    textarea {
      height: 160px;
      max-height: 160px;
      max-width: 100%;
    }

    button {
      background: transparent;
      border: 0;
    }
  }

  .info {
    margin-top: 50px;

    .item {
      .icon {
        float: left;
        padding-top: 5px;
        font-size: 25px;
        color: $main-color;
      }

      .cont-info {
        margin-left: 45px;
        padding-left: 15px;
        border-left: 1px solid rgba(200, 200, 200, 0.4);

        h6 {
          font-size: 14px;
        }
      }
    }
  }
}
