@import '../../assets/scss/common/pixel';
@import '../../assets/scss/common/variables.scss';

.header {
    min-height: 100vh;

    .caption {
        margin-top: 50px;

        h2 {
            font-weight: 200;
        }

        h1 {
            margin: 10px 0;
            font-size: $px36;
            @include breakpoint(md) {
                font-size: $px60;
            }
        }
    }

    .arrow {
        position: absolute;
        bottom: 5vh;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 13px;
        z-index: 8;

        i {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 50%;
                z-index: -1;
                transition: all 0.2s;
            }

            &:hover {
                &:after {
                    transform: scale(1.2);
                    opacity: 0;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.cd-headline {
    font-size: 3rem;
    line-height: 1.2;
}

@media only screen and (min-width: 768px) {
    .cd-headline {
        font-size: 4.4rem;
        font-weight: 300;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-headline {
        font-size: 6rem;
    }
}

.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}

.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
}

.cd-words-wrapper b.is-visible {
    position: relative;
}

.no-js .cd-words-wrapper b {
    opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}

/* xclip */

.cd-headline.clip span {
    display: inline-block;
    padding: 0.2em 0;
}
.cd-headline.clip span.blc {
    display: block;
    @include breakpoint(sm) {
        display: inline-block;
    }
}
.cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}

.cd-headline.clip .cd-words-wrapper::after {
    /* line */
    content: '';
    position: absolute;
    top: 10%;
    right: 0;
    width: 2px;
    height: 70%;
    background-color: #aebcb9;
}

.cd-headline.clip b {
    opacity: 0;
}

.cd-headline b.is-visible {
    opacity: 1;
    font-weight: 900;
}
