$px1: 0.0625rem;
$px2: 0.125rem;
$px3: 0.1875rem;
$px4: 0.25rem;
$px5: 0.3125rem;
$px6: 0.375rem;
$px7: 0.4375rem;
$px8: 0.5rem;
$px9: 0.5625rem;
$px10: 0.625rem;
$px11: 0.6875rem;
$px12: 0.75rem;
$px13: 0.8125rem;
$px14: 0.875rem;
$px15: 0.9375rem;
$px16: 1rem;
$px17: 1.063rem;
$px18: 1.125rem;
$px20: 1.25rem;
$px22: 1.375rem;
$px24: 1.5rem;
$px25: 1.563rem;
$px26: 1.625rem;
$px27: 1.688rem;
$px28: 1.75rem;
$px30: 1.875rem;
$px31: 1.938rem;
$px32: 2rem;
$px34: 2.125rem;
$px36: 2.25rem;
$px35: 2.188rem;
$px38: 2.375rem;
$px40: 2.5rem;
$px42: 2.625rem;
$px44: 2.75rem;
$px45: 2.813rem;
$px46: 2.875rem;
$px48: 3rem;
$px50: 3.125rem;
$px52: 3.25rem;
$px54: 3.375rem;
$px55: 3.438rem;
$px56: 3.5rem;
$px58: 3.625rem;
$px60: 3.75rem;
$px70: 4.375rem;
$px72: 4.5rem;
$px75: 4.688rem;
$px78: 4.875rem;
$px84: 5.25rem;
$px85: 5.313rem;
$px100: 6.25rem;
