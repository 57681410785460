$main-color: #28afe2;
$color-primary: #065596;
$color-primary-hover: #18c9e3;
$color-secondary: #555555;
$color-white: #fff;
$color-black: #000000;
:root {
  --main-color: #28afe2;
  --main-color-rgb: 40, 175, 226;
  --white: #fff;

  .dark-theme {
    --bg-color: #131414;
    --bg-color-rgb: 19, 20, 20;
    --bg-color-rgb-invert: 241, 241, 241;
    --text-color: #eee;
    --text-color-invert: #333;
    --text-paragraph-color: #a3a3a3;
    --dark-rgb-invert: 33, 37, 41;
    --dark-rgb: 248, 249, 250;
    --bs-navbar-brand-color: #eee;
    --bs-heading-color: #eee;
    --border-color: #e3e3e3;
    --border-color-invert: #333;
    --shadow-color: 255, 255, 255;
    --shadow-color-invert: 0, 0, 0;
  }
  .light-theme {
    --bg-color: #f1f1f1;
    --bg-color-rgb: 241, 241, 241;
    --bg-color-rgb-invert: 19, 20, 20;
    --text-color: #333;
    --text-color-invert: #eee;
    --text-paragraph-color: #2a2a2a;
    --dark-rgb-invert: 248, 249, 250;
    --dark-rgb: 33, 37, 41;
    --bs-navbar-brand-color: #333;
    --bs-heading-color: #333;
    --border-color: #333;
    --border-color-invert: #e3e3e3;
    --shadow-color: 0, 0, 0;
    --shadow-color-invert: 255, 255, 255;
  }
}
