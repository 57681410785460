.cButton {
  position: relative;
  cursor: pointer;

  button,
  a {
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    width: 130px;
    transform-style: preserve-3d;
    position: relative;
    transition: 0.3s;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border: 0;
    cursor: pointer;
    &:before {
      content: attr(data-back);
      font-family: "Font Awesome\ 5 Free";
      font-size: 18px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
      color: #28afe2;
      border: 1px solid #28afe2;
      line-height: 38px;
      transition: 0.3s;
      transform-origin: bottom;
      transform: translateY(-100%) rotateX(90deg);
    }
    &:after {
      content: attr(data-front);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #28afe2;
      color: #fff;
      transition: 0.3s;
      transform-origin: top;
      transform: translateY(0) rotateX(0deg);
    }
    &:hover:before {
      transform: translateY(0) rotateX(0deg);
    }
    &:hover:after {
      transform: translateY(100%) rotateX(90deg);
    }
  }
  &-bord {
    button,
    a {
      &:before {
        background: #28afe2;
        color: #fff;
      }
      &:after {
        background: transparent;
        color: #28afe2;
        border: 1px solid #28afe2;
      }
    }
  }

  &-lg {
    button,
    a {
      width: 160px;
    }
  }
  &-dark {
    button,
    a {
      &:before {
        color: #222;
        border: 1px solid #222;
      }
      &:after {
        background: #222;
      }
    }
  }
}
